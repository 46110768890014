import VendorResult from '@components/vendorResult/VendorResult';
import { Column, type ColumnProps, Row } from '@xo-union/tk-component-grid';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { Fragment, type FC, useContext } from 'react';
import WeddingsContext from '../../../contexts/WeddingsContext/WeddingsContext';
import { Divider } from '../containers/SemanticSearch/components/SemanticSearchResults';
import Styles from '../containers/SemanticSearch/components/styles.scss';
import { SponsoredResultsDisclaimer } from './SponsoredResultsDisclaimer';

type SplitResults = (results: Vendor.Raw[]) => {
	paid: Vendor.Raw[];
	unpaid: Vendor.Raw[];
};

export const splitResults: SplitResults = (results) => {
	const paid = [];
	const unpaid = [];

	for (const result of results) {
		if (result.purchaseStatus === 'PAID') {
			paid.push(result);
		} else {
			unpaid.push(result);
		}
	}

	return { paid, unpaid };
};

interface ListingsSectionsResultsProps {
	maxImageCount: number;
	results: Vendor.Raw[];
}

export const ListingsSectionsResults: FC<ListingsSectionsResultsProps> = (
	props,
) => {
	const { maxImageCount, results } = props;
	const { paid, unpaid } = splitResults(results);
	const { onSaveHandler, getIsSaved } = useContext(WeddingsContext);
	const columnSize: ColumnProps = { xs: '12', md: '6', lg: '3' };

	return (
		<>
			{paid.length > 0 && <SponsoredResultsDisclaimer />}
			<Row>
				{paid.map((v, idx) => (
					<Fragment key={v.id}>
						<Column {...columnSize} className={Styles.resultsColumn}>
							<VendorResult
								impressionType="Directory: Main"
								index={idx}
								isSaved={getIsSaved(v.id)}
								maxImageCount={maxImageCount}
								onSaveHandler={onSaveHandler}
								rawVendor={v}
								cardUiLocation="Semantic Search"
								key={v.id}
							/>
						</Column>
						<Divider index={idx} vendorLength={paid.length} />
					</Fragment>
				))}
				{paid.length !== 0 && unpaid.length !== 0 && (
					<Column xs="12">
						<Body1 bold className={Styles.listingsSectionsSubHeader}>
							All other results
						</Body1>
					</Column>
				)}
				{unpaid.map((v, idx) => (
					<Fragment key={v.id}>
						<Column {...columnSize} className={Styles.resultsColumn}>
							<VendorResult
								impressionType="Directory: Main"
								index={idx}
								isSaved={getIsSaved(v.id)}
								maxImageCount={maxImageCount}
								onSaveHandler={onSaveHandler}
								rawVendor={v}
								cardUiLocation="Semantic Search"
								key={v.id}
							/>
						</Column>
						<Divider index={idx} vendorLength={unpaid.length} />
					</Fragment>
				))}
			</Row>
		</>
	);
};
